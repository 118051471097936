const s3Url = "https://sassets.niwcorp.com"; //process.env.NEXT_PUBLIC_S3_SERVER_URL;
export const LOGO_BASE_URL = 'https://niw-staging.s3.amazonaws.com/';
export const serverUrl = "http://localhost:3000"; //process.env.NEXT_PUBLIC_SERVER_URL;
export const externalServerUrl = "https://staging.niwcorp.com"; //process.env.NEXT_PUBLIC_EXTERNAL_SERVER_URL;
export const externalILIAServerUrl = "https://staging.myilia.com"; //process.env.NEXT_PUBLIC_ILIA_SERVER_URL;
export const bucketURL = s3Url + "/CMSImages";
export const stagingServerUrl =  "https://stageapi.myilia.com"; //process.env.NEXT_PUBLIC_API_SERVER_URL;
export const carouselDirectory = "CarouselImages";
export const carouselBucketURL = s3Url + "/CarouselImages";
export const videoBucketUrl = s3Url + "/videos";
export const carriersAssets = s3Url + "/carriers";

export const carrierId = 2; //process.env.NEXT_PUBLIC_CARRIER_ID;
export const carrierName = 'National Life - (LSW)';

export const contactUsEmail = "mohits@simsaw.com"
export const bccEmail = "farhan.kazmi@simsaw.com";

export const showEditIconBlue = 'showEditIconBlue';
export const showEditIconWhite = 'showEditIcon';
export const showEditIconForBg = 'showEditIconForBg';

export const bing_search_content = process.env.NEXT_PUBLIC_BING_SEARCH_CONTENT;
export const google_tag_manager = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
export const google_analytics = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || "UA-199844755-29";
export const facebook_pixel_id = "582076910520676";

export const BUILDER_IO_API_KEY = '7b84975e85bc4190bfeb9dc411fdf08c';
